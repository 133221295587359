import {
    ScmIntegrationsApi,
    scmIntegrationsApiRef,
    ScmAuth,
} from '@backstage/integration-react';
import {
    AnyApiFactory,
    configApiRef,
    createApiFactory
} from '@backstage/core-plugin-api';
import {techRadarApiRef} from "@backstage/plugin-tech-radar";
import {PlatformTechRadarClient} from './PlatformTechRadar'

export const apis: AnyApiFactory[] = [
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: {configApi: configApiRef},
        factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    ScmAuth.createDefaultApiFactory(),
    createApiFactory(techRadarApiRef, new PlatformTechRadarClient()),
];
